<template>
    <v-navigation-drawer 
        v-model="drawerState" v-click-outside="closeFromOverlay" clipped :stateless="drawerStateless" fixed temporary right :width="rightDrawerWidth"
        class="grey lighten-3" style="z-index:1054;"><!-- stateless -->
        <v-toolbar dark dense :color="(element.state == 'modifier') ? 'red darken-4' : 'primary'" class="elevation-1 ">
            <!-- <v-btn icon small :href="link.consult"><v-icon>{{(element.state == 'apercu') ? 'mdi-close' : 'mdi-arrow-left'}}</v-icon></v-btn> -->
            <v-menu v-model="sliderMenu" :close-on-content-click="false" :nudge-width="600" offset-y z-index="1100">
                <template v-slot:activator="{ on: menu }">
                <v-tooltip right open-delay="500">
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn v-on="{ ...tooltip, ...menu }" icon slot="activator">
                        <v-badge color="red" class="mt-1" :content="bookmarkCount" :value="bookmarkCount">
                            <v-icon>mdi-card-bulleted-outline</v-icon>
                        </v-badge>
                        </v-btn>
                    </template>
                    <span>Voir les fiches en favoris</span>
                </v-tooltip>
                </template>
                <TaskOpenedList class="mt-2" style="z-index:900 !important;" :tabsheetCount="bookmarkCount" :menuState="openCardState"></TaskOpenedList>
            </v-menu>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn 
                        x-small fab depressed :color="(bookmark['b-' + objTask.tsk_id]) ? 'amber darken-1' : 'grey darken-1'"
                        class="ma-1" @click="addToBookmark()" v-on="on"><v-icon>mdi-star</v-icon>
                    </v-btn>
                </template>
                <span>Ajout favoris</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn x-small fab depressed color="light-blue darken-1" class="ma-1" @click="leftDrawerMaximize()" v-on="on"><v-icon>mdi-window-maximize</v-icon></v-btn>
                </template>
                <span>{{ labels.windowSize }}</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn x-small fab depressed color="red darken-1" class="ma-1" @click="closeSlider" v-on="on"><v-icon>mdi-close</v-icon></v-btn> <!-- :href="link.base" -->
                </template>
                <span>Fermer</span>
            </v-tooltip>
        </v-toolbar>
        <!-- Composant dynamiques -->
        <v-progress-linear v-if="!openRoute" indeterminate color="blue" height="5"></v-progress-linear>
        <router-view       v-if="openRoute" name="content" :link="link" :routeState="element" :objTask="objTask" :closeSlider="closeSlider"></router-view>
    </v-navigation-drawer>
</template>

<script>
import TaskOpenedList      from '@/components/tasks/TaskOpenedList.vue'
import { tskObj }   from '@/mixins/tsk_obj.js'
import { cacheGetters, cacheActions, cacheMutations } from "@/store/cache.module"
import { usrGetters }   from "@/store/user.module"
import { bus } from '@/plugins/bus'

export default {
    name: 'slider',
    components: { TaskOpenedList },
    props: ['toast', 'subAreaSelected'],
    mixins: [ tskObj ],
    data: function () {
        return {
            drawerState: false,
            openRoute: false,
            labels: { windowSize: 'Agrandir' },
            element: { cardView:false, cardEdit:false, view:false, edit:false, add:false, state: 'action' },
            link: { base: '', site: '', consult: '' },
            sliderMenu: false,
            drawerStateless: true,
            rightDrawerWidth: '1200px',
            bookmark: {},
            isAuthorized: usrGetters.getScopeRight
        }
    },
    watch: {
        $route () {
            this.controlRoute()
        }
    },
    computed: {
        linkClose(){
            return this.link.base
        },
        sliderMode(){
            let params = this.link.consult.split('/')
            return params.length
        },
        bookmarkCount(){
            var total = 0
            var item
            for( item in this.bookmark ){
                if( item ){
                    total++
                }
            }
            return total
        }
    },
    beforeMount: function(){
        let open = false
        open     = (this.$route.params.tsk_id !== undefined && this.$route.params.tsk_id > 0) ? true : open
        open     = (this.$route.params.sar_id !== undefined && this.$route.params.sar_id > 0) ? true : open
        if( open ){
            this.objTask.tsk_id = parseInt(this.$route.params.tsk_id)
            this.drawerState = true
        }
    },
    beforeUpdate(){
        this.link.base = '/#' + this.$route.matched[1].path
        this.bookmark  = cacheGetters.getBookmark()
    },
    mounted() {
        this.link.base = '/#' + this.$route.matched[1].path
        this.controlRoute()
    },
    methods:{
        closeSlider() {
            this.$router.push( { path: this.$route.matched[1].path } )
            bus.$emit('massedit:close', 1)
        },
        closeFromOverlay(e) {
            if(this.drawerState && e.target.className === "v-overlay__scrim") {
                this.$router.push( { path: this.$route.matched[1].path } )
                bus.$emit('massedit:close', 1)
            } // todoo : optimise
        },
        openCardState(state){
            this.sliderMenu = state
        },
        leftDrawerMaximize(){
            if( this.rightDrawerWidth == '900px' ){
                this.rightDrawerWidth = '100%'
                this.labels.windowSize= 'Réduire'
            } else {
                this.rightDrawerWidth = '900px'
                this.labels.windowSize= 'Agrandir'
            }
        },
        adaptSidebar(to){
            const pathList = to.fullPath.split('/')
            if( to.params.tsk_id !== undefined && to.params.tsk_id > 0 ){
                if( pathList[4] !== undefined ){
                    this.link.consult = "#/" + pathList[1] + '/' + pathList[2] + '/' + pathList[3]
                } else {
                    this.link.consult = "#/" + pathList[1]
                }
            } else {
                this.objTask.tsk_id = to.params.tsk_id
            }
            if( to.params.tsk_id !== undefined && to.params.tsk_id == 'nouveau' ){
                this.element.edit  = true
                this.element.view  = true
                this.element.state      = 'nouveau'
            } else {
                this.element.edit  = false
                this.element.view  = true
                this.element.state      = 'apercu'
            }
            if( pathList[4] !== undefined && pathList[4] == 'modifier' ){
                this.element.edit  = true
                this.element.view  = false
                this.element.state      = 'modifier'
            }
            if( pathList[4] !== undefined && pathList[4] == 'action' ){
                this.element.edit  = false
                this.element.view  = true
                this.element.state      = 'action'
            }
            if( pathList[1] !== undefined && pathList[1] == 'sites' ){
                this.rightDrawerWidth = '1600px'
            } else {
                this.rightDrawerWidth = '900px'
            }
            if( pathList[4] !== undefined ){
                this.link.consult = "#/" + pathList[1] + '/' + pathList[2] + '/' + pathList[3]
            } else {
                this.link.consult = "#/" + pathList[1]
            }
        },
        addToBookmark(){
            if( this.bookmark['b-' + this.objTask.tsk_id] !== undefined ){
                //delete this.bookmark['b-' + this.objTask.tsk_id]
                this.$delete(this.bookmark, 'b-' + this.objTask.tsk_id)
                cacheMutations.setBookmark(this.bookmark)
                cacheActions.storeCacheToStorage()
            } else if(this.objTask.tsk_id) {
                this.getTaskShow('tree').then( () => {
                    let newBookmark = JSON.parse( JSON.stringify(this.objTask))
                    this.$set(this.bookmark, 'b-' + this.objTask.tsk_id, newBookmark)
                    cacheMutations.setBookmark(this.bookmark)
                    cacheActions.storeCacheToStorage()
                })
            }
        },
        controlRoute(){
            let validRoute = false
            validRoute     = ( this.$route.params.tsk_id !== undefined ) ? true : false
            validRoute     = ( validRoute && this.$route.params.tsk_id > 0 ) ? true : false
            if( this.$route.params.tsk_id == 0 && this.$route.params.tre_id >= 0 ){
                validRoute = true
            }
            this.drawerState = ( validRoute && this.$route.params.tsk_id !== undefined ) ? true : false
            // To refresh data if route is manualy changed or by bookmark
            if( validRoute && this.$route.params.tsk_id !== this.objTask.tsk_id ){
                this.objTask.tsk_id = parseInt(this.$route.params.tsk_id)
                this.openRoute = false
                setTimeout( () => {
                    this.drawerState = true
                    this.openRoute = true
                }, 400)
            }
        }
    },
    beforeRouteEnter(to, from, next){
        next(vm => {
            vm.adaptSidebar(to)
        })   
    },
    beforeRouteUpdate(to, from, next) {
        this.adaptSidebar(to)
        next()
    },
}

</script>
